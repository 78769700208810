import React, { useState, useEffect } from "react";
import "./login.css";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import logo from "../../utilities/logo-png.png";
import loginImg from "../../utilities/login-img.png";
import { Alert, Snackbar } from "@mui/material";

const Login = (props) => {
  const [user, setUser] = useState(null);

  // States
  const [dropdown, setDropdown] = useState("Select Role");
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const handlePass = (e) => {
    setPass(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email === "") {
      // alert("Enter Your Email");
      setMsg("*Please Enter Email*");
      return;
    }
    if (pass === "") {
      setMsg("*Please Enter Your Password*");
      // alert("Enter Your Password");
      return;
    }
    if (dropdown === "Select Role") {
      setMsg("*Please Select Your Role*");
      // alert("Select Proper Role");
      return;
    }

    const url = `https://hr-backend-72v1.onrender.com/login?email=${encodeURIComponent(
      email
    )}&pass=${encodeURIComponent(pass)}&role=${encodeURIComponent(dropdown)}`;
    window.open(url, "_self");
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    if (error) {
    //   console.log(error);
      setAlertMessage(error);
      setShowAlert(true);
    }
  }, []);

  return (
    <Container fluid className="d-flex vh-100" style={{ padding: "0px" }}>
      <Row className="w-100">
        <Col
          md={7}
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
        >
          <div className="text-center">
            <img
              src={logo}
              style={{ width: "190px", height: "200px" }}
              alt="logo"
            />
            <h4 className="mt-1 mb-3 pb-1" style={{ fontWeight: "bold" }}>
              Welcome To Growth Guild
            </h4>
          </div>

          <p className="mb-9 pb-4">Please Enter Your Details</p>

          <Form.Group className="mb-4" style={{ width: "60%" }}>
            <Form.Control
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                backgroundColor: "#EAE2E2",
              }}
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmail}
            />
          </Form.Group>

          <Form.Group className="mb-4" style={{ width: "60%" }}>
            <Form.Control
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                backgroundColor: "#EAE2E2",
              }}
              type="password"
              placeholder="Password"
              value={pass}
              onChange={handlePass}
            />
          </Form.Group>

          <InputGroup style={{ width: "30%", margin: "0 auto" }}>
            <DropdownButton
              variant="outline-secondary"
              title={dropdown}
              id="input-group-dropdown-1"
            >
              <Dropdown.Item onClick={() => setDropdown("Employee")}>
                Employee
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setDropdown('Manager')}>Manager</Dropdown.Item> */}
              <Dropdown.Item onClick={() => setDropdown("HR")}>
                HR
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setDropdown('Executive')}>Executive</Dropdown.Item> */}
            </DropdownButton>
          </InputGroup>
          <h5 className="heading1">{msg}</h5>

          <div
            className="text-center pt-1 mb-5 mt-4 pb-1"
            style={{ width: "70%" }}
          >
            <Button
              className="sign-in-btn mb-4 gradient-custom-2"
              onClick={handleLogin}
              style={{
                width: "80%",
                outline: "none",
                border: "none",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Sign In
            </Button>
          </div>
        </Col>

        <Col
          md={5}
          className="d-flex flex-column justify-content-center align-items-center text-white gradient-custom-3"
          style={{ height: "100%", overflow: "hidden" }}
        >
          <img
            src={loginImg}
            alt="logo"
            style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }}
          />
        </Col>
        <Snackbar
          open={showAlert}
          autoHideDuration={15000}
          onClose={() => setShowAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setShowAlert(false)} severity="error">
            {alertMessage}
          </Alert>
        </Snackbar>
      </Row>
    </Container>
  );
};

export default Login;
