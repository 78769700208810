import React, { useState, useEffect } from "react";
import "./OtherProjects.css";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Alert,
} from "@mui/material";
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
// import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
// import MasterMind from "../../../utilities/mastermind.png";
import logo from "../../../utilities/logo-png.png";
import handshake from "../../../utilities/handshake.png";
import NewRetainer from "./NewRetainer";

const Retainers = (props) => {
  const [emails, setEmails] = useState([]);
  const [showRetainers, setshowRetainers] = useState(true);
  const [messageType, setMessageType] = useState("success");
  const [message, setMessage] = useState(null);
  const [openRetainerAdd, setopenRetainerAdd] = useState(false);
  const [openRetainerEmployeeAdd, setopenRetainerEmployeeAdd] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [openRetainer, setOpen] = useState(false);

  const [retainers, setRetainers] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Effect to filter data based on search term
  // useEffect(() => {
  //   if (searchTerm) {
  //     const results = props.masterMindData.filter((report) =>
  //       report.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredData(results);
  //   } else {
  //     setFilteredData(props.masterMindData);
  //   }
  // }, [searchTerm, props.masterMindData]);

  // const getUniqueClientCount = (data) => {
  //   if (data !== null && data.length > 0) {
  //     const clientNames = new Set();
  //     data.forEach(item => clientNames.add(item.clientName));
  //     return clientNames.size;
  //   }
  //   return 0; // Return 0 if data is null or an empty array
  // };

  // // Usage in your component's return statement or render method
  // const uniqueClientCount = getUniqueClientCount(props.retainersData);

  const handleOpenRetainerAdd = () => {
    setopenRetainerAdd(true);
  };

  const handleCloseRetainerAdd = () => {
    setopenRetainerAdd(false);
  };
  const handleOpenRetainerEmployeeAdd = () => {
    setopenRetainerEmployeeAdd(true);
  };

  const handleCloseRetainerEmployeeAdd = () => {
    setopenRetainerEmployeeAdd(false);
  };
  const handleToggle = (tab) => {
    setshowRetainers(tab === "retainers");
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/employees/emails");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data);
        // console.log(data);
        setEmails(data);
      } catch (error) {
        console.error("Error fetching emails:");
      }
    };
    fetchEmails();
  }, []);
  useEffect(() => {
    // Show the message if it's present, and auto-hide after 5 seconds
    if (message) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);
  useEffect(() => {
    fetch("https://hr-backend-72v1.onrender.com/api/retainers")
      .then((res) => res.json())
      .then(setRetainers)
      .catch(console.error("Error while getting retainers"));
  }, []);
console.log(retainers);
  // const handleSendEmployee = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://hr-backend-72v1.onrender.com/api/project/addemployee",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           projectName: props.heading, // Assuming 'heading' is the project name
  //           employee: {
  //             name: selectedEmail.name,
  //             email: selectedEmail.email,
  //             startingDate: new Date(), // Sets today's date
  //           },
  //         }),
  //       }
  //     );

  //     const result = await response.json();
  //     if (response.ok) {
  //       // console.log('Employee added:', result);
  //       setMessage("Employee added successfully");
  //       setMessageType("success");
  //     } else {
  //       setMessage("Error Adding Employee");
  //       setMessageType("error");
  //       throw new Error(result.message);
  //     }
  //   } catch (error) {
  //     // console.error('Failed to add employee:', error.message);
  //     setMessage("Error Adding Employee");
  //     setMessageType("error");
  //   }
  // };

  const calculateTenure = (joiningDate) => {
    const now = new Date();
    const joinDate = new Date(joiningDate);
    const years = now.getFullYear() - joinDate.getFullYear();
    const months = now.getMonth() - joinDate.getMonth() + years * 12;
    const days =
      now.getDate() >= joinDate.getDate()
        ? now.getDate() - joinDate.getDate()
        : new Date(
            joinDate.getFullYear(),
            joinDate.getMonth() + 1,
            0
          ).getDate() -
          joinDate.getDate() +
          now.getDate();

    const fullMonths = Math.floor(months + days / 30);
    const fullDays = Math.floor(days % 30);

    return `${fullMonths} month${fullMonths !== 1 ? "s" : ""} ${fullDays} day${
      fullDays !== 1 ? "s" : ""
    }`;
  };

  return (
    <div>
      {showMessage && (
        <div
          className="animate__animated animate__slideInDown"
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={messageType} style={{ marginBottom: "20px" }}>
            {message}
          </Alert>
        </div>
      )}

      <div className="masterTop">
        <Button
          className="backButton"
          sx={{ border: "2px solid", marginLeft: "5%" }}
          onClick={props.onBack}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4 className="myTableHeader masterHeader animate__animated animate__lightSpeedInLeft">
          {props.heading}
        </h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "500px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "15px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleOpenRetainerEmployeeAdd}
              style={{
                textAlign: "center",
                alignContent: "center",
                height: "50px",
              }}
            >
              New Employee
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenRetainerAdd}
              style={{
                textAlign: "center",
                alignContent: "center",
                height: "50px",
              }}
            >
              New Retainer
            </Button>
          </div>
        </div>
      </div>

      <div className="masterMid">
        <Table sx={{ minWidth: 250 }} className="masterTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Total Retainers</span>
              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                <span className="myTableHeadSpan">
                  Total Employees Associated
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="numbers" align="left" sx={{paddingLeft:"50px"}}>
                {retainers !== "" && retainers.length}
              </TableCell>
              <TableCell className="numbers" align="center" sx={{paddingLeft:"50px"}}>
                {props.retainersData && props.retainersData.length}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className="masterImage">
          <img src={props.image === "logo" ? logo : handshake} width="30%" />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          marginTop: "20px",
          marginLeft: "70px",
          gap: "20px",
        }}
      >
        <Button
          variant={showRetainers ? "contained" : "outlined"}
          onClick={() => handleToggle("retainers")}
        >
          Retainers
        </Button>
        <Button
          variant={!showRetainers ? "contained" : "outlined"}
          onClick={() => handleToggle("employees")}
        >
          Employees Associated
        </Button>
      </div>

      {!showRetainers && (
        <TableContainer
          component={Paper}
          className="leaveContainer"
          style={{ width: "100% !important" }}
        >
          <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
            Employees
          </h4>
          <Table sx={{ minWidth: 650 }} className="leaveTable">

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="myTableHeadCells">Name</TableCell>
                      <TableCell className="myTableHeadCells">
                        Joining Date
                      </TableCell>
                      <TableCell className="myTableHeadCells">Tenure</TableCell>
                      <TableCell className="myTableHeadCells">
                        Client Name
                      </TableCell>
                      {/* <TableCell className="myTableHeadCells" align="right">
                      Status
                    </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.retainersData &&
                      props.retainersData.map((report, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontSize: "large" }}>
                            {report.name}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {new Date(report.joiningDate).toLocaleDateString()}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {calculateTenure(report.joiningDate)}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {report.clientName}
                          </TableCell>
                          {/* <TableCell sx={{ fontSize: "large" }} align="right">
                          <span className={report.status.toLowerCase()}>
                            {report.status}
                          </span>
                        </TableCell> */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
          </Table>
        </TableContainer>
      )}
      {showRetainers && (
        <TableContainer
          component={Paper}
          className="leaveContainer"
          style={{ width: "100% !important" }}
        >
          <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
            Retainers
          </h4>
          <Table sx={{ minWidth: 650 }} className="leaveTable">
            {props.image === "Retainers" && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="myTableHeadCells">Name</TableCell>
                      <TableCell className="myTableHeadCells">
                        Joining Date
                      </TableCell>
                      <TableCell className="myTableHeadCells">Total Days</TableCell>
                      <TableCell className="myTableHeadCells" align="center">Working Employees</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {retainers &&
                      retainers.map((report, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontSize: "large" }}>
                            {report.clientName}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {new Date(report.startingDate).toLocaleDateString()}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {calculateTenure(report.startingDate)}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }} align="center">
                            {report.employees.length}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Table>
        </TableContainer>
      )}
      <div>
        <NewRetainer
          open={openRetainerAdd}
          openRetainerEmployeeAdd={openRetainerEmployeeAdd}
          handleOpenRetainerAdd={handleOpenRetainerAdd}
          handleCloseRetainerAdd={handleCloseRetainerAdd}
          handleOpenRetainerEmployeeAdd={handleOpenRetainerEmployeeAdd}
          handleCloseRetainerEmployeeAdd={handleCloseRetainerEmployeeAdd}
          emails={emails}
        />
      </div>
    </div>
  );
};

export default Retainers;
