import React, { useState, useEffect } from "react";
import "./Profiles.css";
import { Avatar } from "@mui/material";
import PersonalDetails from "../../Employee/Personals/PersonalDetails";
import "../SearchContainer.css";
import ReplayIcon from "@mui/icons-material/Replay";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";

import { Card } from "react-bootstrap";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, TextField } from "@mui/material";
const Profile = (props) => {
  const user = props.user;
  const [emails, setEmails] = useState([]);
  const [emailsD, setEmailsD] = useState([]);
  const [archives, setArchives] = useState([]);

  const [Employee, setEmployee] = useState(null);
  const [allEmployees, setAllEmployees] = useState(null);
  const [showProfileToggle, setshowProfileToggle] = useState(false);
  const [show, setShow] = useState("dep");
  const [showOneD, setShowOneD] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [filteredReports, setfilteredReports] = useState([]);
  const allDepartments = [
    "Content Team",
    "Dev Team",
    "Finance",
    "HR",
    "Media Buying",
    "Marketing Team",
  ];

  const handleSearchByName = (event) => {
    setSearchName(event?.target.value);
    const filteredReports = emails.filter((report) =>
      report.name.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setfilteredReports(filteredReports);
  };

  const updateEmployeeField = async (email, field, value) => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/updateEmployee", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          field,
          value,
        }),
      });
      const data = await response.json();
      if (data.success) {
        alert("Employee updated successfully");
        fetchEmployeeDetails(allEmployees.email);
      } else {
        alert("Error updating employee");
      }
    } catch (error) {
      alert("Error updating employee");
      // console.error(error);
    }
  };

  const inputEditHandler = (email, field, value, toggleEditField) => {
    updateEmployeeField(email, field, value);
    toggleEditField(false);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/employees/emails");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEmails(
          data.sort((a, b) => (b.manager === "yes") - (a.manager === "yes"))
        );
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
    const fetchArchives = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/past-employees");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setArchives(data);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
    fetchEmails();
    fetchArchives();
  }, []);

  const fetchEmployeeDetails = async (email) => {
    try {
      const response = await fetch(
        `https://hr-backend-72v1.onrender.com/allemployees/${email}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("data in here  ", data);
      // console.log("data in here  ", data);
      setAllEmployees(data);
      setshowProfileToggle(true);
      // setcurrentEmployeeChangeEmail(data.email);
    } catch (error) {
      console.error("Error fetching employee details:");
    }
  };
  const handleDontShowProfile = () => {
    setshowProfileToggle(false);
  };
  const departmentClickHandler = (d) => {
    setEmailsD(emails.filter((e) => e.department === d));
    setShowOneD(true);
  };
  const refreshHandler = () => {
    handleSearchByName({ target: "" });
    setSearchName("");
  };

  console.log("emplss:", emailsD);
  /////////////////////////////////////////////////////////////////////////////////////////// https://hr-backend-72v1.onrender.com
  return (
    <>
      {showOneD && !showProfileToggle && (
        <div
          style={{
            textAlign: "left",
            alignContent: "left",
            marginTop: "20px",
            marginLeft: "5%",
          }}
          className="go-back-img"
        >
          <Button
            className="backButton"
            sx={{ border: "2px solid" }}
            onClick={() => setShowOneD(false)}
          >
            <ArrowBackIosNewIcon fontSize="large" />{" "}
          </Button>
        </div>
      )}
      {!showProfileToggle && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
              Employee Details
            </h4>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                boxShadow: "-3px 2px 4px rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {show === "dep" && !showOneD
                ? allDepartments.length
                : showOneD
                ? emailsD.length
                : show === "sim" && filteredReports.length > 0
                ? filteredReports.length
                : show === "sim"
                ? emails.length
                : archives.length}
            </span>{" "}
            {/* Replace 100 with dynamic value */}
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              gap: "20px",
              zIndex: "1000",
            }}
          >
            <Button
              variant={show === "dep" ? "contained" : "outlined"}
              onClick={() => {
                setShow("dep");
                setShowOneD(false);
                // handleSearchByName({target:" "});
              }}
            >
              Department View
            </Button>
            <Button
              variant={show === "sim" ? "contained" : "outlined"}
              onClick={() => {
                setShow("sim");
                setShowOneD(false);
              }}
            >
              Simple View
            </Button>
            <Button
              variant={show === "arc" ? "contained" : "outlined"}
              onClick={() => {
                setShow("arc");
                setShowOneD(false);
              }}
            >
              Archives
            </Button>
          </div>
        </>
      )}

      {!showProfileToggle && show === "dep" && (
        <div className="cardContainerP">
          {(showOneD ? emailsD : allDepartments).map((d) => {
            const de = typeof d === "string";
            return (
              <Card className="cardProfile animate__animated animate__fadeInUp">
                {!de && d.manager === "yes" && (
                  <span className="manager-badge">
                    <StarBorderPurple500Icon fontSize="larger" />
                  </span>
                )}
                <Card.Header className="cardProfileHeader">
                  <Avatar className="avatarr">{de ? d[0] : d.name[0]}</Avatar>
                </Card.Header>
                <Card.Body className="cardProfileBody">
                  <h4>{de ? d : d.name}</h4>
                  {!de && <p>{d.pos}</p>}

                  {de ? (
                    <Button onClick={() => departmentClickHandler(d)}>
                      View <ChevronRightIcon />
                    </Button>
                  ) : (
                    <Button onClick={() => fetchEmployeeDetails(d.email)}>
                      View Details
                    </Button>
                  )}
                </Card.Body>
              </Card>
            );
          })}
        </div>
      )}
      {!showProfileToggle && show === "sim" && (
        <div className="cardContainerP">
          <div
            className="search-container"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              marginBottom: "30px",
            }}
          >
            <div class="night">
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
            </div>
            <div
              className="search-fields"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  backgroundColor: "#4484FF",
                  borderRadius: "10px",
                  borderRadius: "10px",
                  "& .MuiInputLabel-root": { color: "#ffad3d" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#ffad3d" },
                    "&:hover fieldset": { borderColor: "#ffad3d" },
                    "&.Mui-focused fieldset": { borderColor: "#ffad3d" },
                    "& input": { color: "#ffad3d" },
                  },
                }}
                onChange={handleSearchByName}
                value={searchName}
                className="search-input"
                label="Search By Name"
                variant="outlined"
              />
            </div>
            <button
              style={{
                position: "absolute",
                right: "20px",
                top: "20px",
                color: "white",
                background: "none",
                border: "none",
              }}
              onClick={refreshHandler}
            >
              <ReplayIcon />
            </button>
          </div>
          {(filteredReports.length > 0 ? filteredReports : emails).map(
            (employee) => {
              return (
                <Card className="cardProfile animate__animated animate__fadeInUp">
                  {employee.manager === "yes" && (
                    <span className="manager-badge">
                      <StarBorderPurple500Icon fontSize="larger" />
                    </span>
                  )}
                  <Card.Header className="cardProfileHeader">
                    <Avatar className="avatarr">{employee.name[0]}</Avatar>
                  </Card.Header>
                  <Card.Body className="cardProfileBody">
                    <h4>{employee.name}</h4>
                    <p>{employee.pos}</p>
                    <Button
                      onClick={() => fetchEmployeeDetails(employee.email)}
                    >
                      View Details
                    </Button>
                  </Card.Body>
                </Card>
              );
            }
          )}
        </div>
      )}
      {!showProfileToggle && show === "arc" && (
        <div className="cardContainerP">
          {archives.map((arc) => {
            return (
              <Card className="cardProfile animate__animated animate__fadeInUp">
                <Card.Header className="cardProfileHeader">
                  <Avatar className="avatarr">{arc.name[0]}</Avatar>
                </Card.Header>
                <Card.Body className="cardProfileBody">
                  <h4>{arc.name}</h4>
                  <p>{arc.pos}</p>
                  {/* <Button
                      onClick={() => fetchEmployeeDetails(arc.email)}
                    >
                      View Details
                    </Button> */}
                </Card.Body>
              </Card>
            );
          })}
        </div>
      )}

      {allEmployees && showProfileToggle && (
        <PersonalDetails
          user={user}
          Employee={allEmployees}
          handleDontShowProfile={handleDontShowProfile}
          //SOmthing
          inputEditHandler={inputEditHandler}
        />
      )}
    </>
  );
};

export default Profile;
