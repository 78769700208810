import React, { useState, useEffect, useRef } from "react";
import "./employee.css";
import "../button.css";
import Navbar from "../Navbar/navbar";
import Sidebar from "./Sidebar/sidebar";
import PersonalDetails from "./Personals/PersonalDetails";
import RequestLeave from "./RequestLeave/RequestLeave";
import Events from "../HR/Events/Events";
// import Empcountdowns from './Empcountdowns/Empcountdowns';
import Dashboard from "../HR/Dashboard/Dashboard";
import StartIcon from "../../utilities/start.svg";
import PauseIcon from "../../utilities/pause.svg";
import ResumeIcon from "../../utilities/resume.svg";
import StopIcon from "../../utilities/stop.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationComponent from "../../NotificationCard";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TableContainer,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  Snackbar,
  Alert,
} from "@mui/material";

// import Dashboard from './Dashboard/Dashboard';

// import {
//   Container,
//   Row,
//   Col,
//   Breadcrumb,
//   Card,
//   ListGroup,
//   ProgressBar,
// } from "react-bootstrap";
// import logo from "../../utilities/logo-white.jpeg";

// import DialogTitle from '@mui/material/DialogTitle';
// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { useTimer } from 'react-timer-hook';

// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import DoneIcon from "@mui/icons-material/Done";
// import { Input } from "@mui/material";

const Employee = (props) => {
  const user = props.user;
  // const [lastInactive, setLastInactive] = useState(null);
  // const [selectedOption, setSelectedOption] = useState("projectA");
  // const [profileText, setprofileText] = useState("Show Profile");
  const [profile, setprofile] = useState(false);
  const [Employee, setEmployee] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabled2, setisDisabled2] = useState(true);
  // const [toggleEditA, settoggleEditA] = useState(false);
  // const [toggleEditB, settoggleEditB] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState("");
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [leaveReason, setLeaveReason] = useState("");
  const [reportDetail, setreportDetail] = useState("");
  const [profileChange, setprofileChange] = useState("");
  const [leaveDays, setLeaveDays] = useState("");
  // const [open, setOpen] = useState(false);
  // const [userLeaves, setUserLeaves] = useState([]);
  // const [showUserLeaves, setShowUserLeaves] = useState(false);
  // const [reason, setReason] = useState("");
  // const [pauseTimestamp, setPauseTimestamp] = useState(null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  // const intervalRef = useRef(null);
  const timeoutRef = useRef(null);
  // const [appointmentTaken, setAppointmentTaken] = useState("");
  // const [emailSent, setEmailSent] = useState("");
  // const [pipelineMoved, setPipelineMoved] = useState("");
  // const ws = useRef(null);
  // const [dashbaordToggle, setDashbaordToggle] = useState(true);
  // const [selectedProject, setselectedProject] = useState("");
  const [timerShowToggle, setTimerShowToggle] = useState(true);
  const [myProfileToggle, setmyProfileToggle] = useState(false);
  const [eventsToggle, seteventsToggle] = useState(false);
  const [requestLeaveToggle, setrequestLeaveToggle] = useState(false);
  const [employeesCountdownToggle, setemployeesCountdownToggle] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [showMainReport, setShowMainReport] = useState(false);
  const [messageLine, setMessageLine] = useState();
  const [messageToggle, setMessageToggle] = useState(false);

  // const handleProjectDropChange = (event) => {
  //   setselectedProject(event.target.value);
  // };
  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  };

  const dashbaordToggleHandler = () => {
    setTimerShowToggle(true);
    setmyProfileToggle(false);
    seteventsToggle(false);
    setrequestLeaveToggle(false);
    setemployeesCountdownToggle(false);
  };
  const profileToggleHandler = () => {
    setTimerShowToggle(false);
    setmyProfileToggle(true);
    seteventsToggle(false);
    setrequestLeaveToggle(false);
    setemployeesCountdownToggle(false);
  };
  const eventsToggleHandler = () => {
    setTimerShowToggle(false);
    setmyProfileToggle(false);
    seteventsToggle(true);
    setrequestLeaveToggle(false);
    setemployeesCountdownToggle(false);
  };

  const requestALeaveToggleHandler = () => {
    setTimerShowToggle(false);
    seteventsToggle(false);
    // setDashbaordToggle(false);
    setmyProfileToggle(false);
    setrequestLeaveToggle(true);
    setemployeesCountdownToggle(false);
  };

  const employeesCountdownHandler = () => {
    setTimerShowToggle(false);
    seteventsToggle(false);
    // setDashbaordToggle(false);
    setmyProfileToggle(false);
    setrequestLeaveToggle(false);
    setemployeesCountdownToggle(true);
  };

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const handleLeaveChange = (event) => {
  //   setSelectedLeave(event.target.value);
  //   setIsDialogOpen(true);
  // };

  // const handleAppointmentTakenChange = (event) => {
  //   setAppointmentTaken(event.target.value);
  // };

  // const handleEmailSentChange = (event) => {
  //   setEmailSent(event.target.value);
  // };

  // const handlePipelineMovedChange = (event) => {
  //   setPipelineMoved(event.target.value);
  // };

  const handleStopClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmSubmit = () => {
    handleStop();
    setShowMainReport(true);
    setOpenDialog(false);
  };

  // const handleShowMyLeaves = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://hr-backend-72v1.onrender.com/leaves?email=${user.email}`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch user leaves");
  //     }
  //     const data = await response.json();
  //     setUserLeaves(data);
  //     setShowUserLeaves(true); // Open the dialog when leaves are fetched
  //     setOpen(true);
  //   } catch (error) {
  //     console.error("Error fetching user leaves:");
  //   }
  // };

  // const handleShowReason = (reason) => {
  //   setSelectedLeave(reason); // Set the selected leave's reason to display
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setShowUserLeaves(false);
  // };

  // const handleClickOpen = (reason) => {
  //   setReason(reason);
  //   setOpen(true);
  // };

  const handleDialogClose = () => {
    // setIsDialogOpen(false);
    setLeaveReason("");
    setLeaveDays("");
  };

  // const handleReportClose = () => {
  //   setreport(false);
  // };

  const handleReasonChange = (event) => {
    setLeaveReason(event.target.value);
  };

  const handleReportDetail = (event) => {
    setreportDetail(event.target.value);
  };
  // const handleProfileChange = (event) => {
  //   setprofileChange(event.target.value);
  // };

  // const handleCheckboxChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  // const handleProfileShow = () => {
  //   if (!profile) {
  //     setprofileText("Close Profile");
  //   } else {
  //     setprofileText("Show Profile");
  //   }
  //   setprofile(!profile);
  // };

  const handleDaysChange = (event) => {
    setLeaveDays(event.target.value);
  };

  const handleReportSubmit = async () => {
    try {
      let reportData;
      reportData = {
        name: user.name,
        email: user.email,
        reportDetail,
      };

      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/reports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reportData),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong, Please try again..");
      } else {
        setShowAlert(true);
        setAlertMessage("Report Submitted Successfully !");
        setShowMainReport(false);
        setMessageToggle(false);
        setisDisabled2(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      // const responseData = await response.text();
      // console.log('Progress Report Response:', responseData);
      // alert("Report Submitted Successfully");
    } catch (error) {
      alert("There Was an Error Submitting Your Request");
      // console.error('Error submitting report:', error);
    }
  };

  const handleProfileChangeSubmit = async () => {
    if (!profileChange.trim()) {
      alert("Profile change request detail cannot be empty.");
      return;
    }

    const response = await fetch(
      "https://hr-backend-72v1.onrender.com/requestProfileChange",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: Employee.email,
          changeRequest: profileChange,
        }),
      }
    );

    const result = await response.json();

    if (result.success) {
      alert("Profile change request submitted successfully.");
      setprofileChange("");
    } else {
      alert("Failed to submit profile change request.");
    }
  };

  const handleSubmit = async () => {
    if (!selectedLeave || !leaveReason || !leaveDays) {
      alert("Please fill in all fields.");
      return;
    }

    const leaveData = {
      leaveType: selectedLeave,
      email: user.email,
      date: new Date().toISOString(),
      requestedDays: leaveDays,
      reason: leaveReason,
      name: user.name,
      status: "Pending",
    };

    try {
      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/applyleave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(leaveData),
        }
      );

      if (response.ok) {
        alert("Leave applied successfully.");
        handleDialogClose();
      } else {
        const errorData = await response.json();
        // console.error('Error applying leave:', errorData);
        alert("Failed to apply leave. Please try again.");
      }
    } catch (error) {
      // console.error('Error applying leave:', error);
      // console.error('Error applying leave:', error);
      alert("Failed to apply leave. Please try again.");
    }
  };

  // const inputEditHandler = () => {
  //   settoggleEditE(false);
  //   settoggleEditP(false);
  //   settoggleEditA(false);
  //   settoggleEditB(false);
  // };

  const handleLogout = () => {
    // beforeUnloadHandler();
    // console.log(user);
    localStorage.removeItem("userValue");
    window.location.reload();
  };

  const employee = {
    name: user.name,
    email: "example@example.com",
    phone: "0900 78601",
    address: "California , USA",
    bank: "Easypaisa / 03123456789",
    joining: "1 January, 2024",
    designation: "Junior Developer",
    salary: 25000, //
  };

  useEffect(() => {
    //
    const loginEmployee = async () => {
      try {
        const response = await fetch(
          `https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`
        );
        const data = await response.json();

        if (response.ok) {
          // console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error("Login failed: response is not ok");
        }
      } catch (error) {
        console.error("Error during login:");
      }
    };

    loginEmployee();
  }, [user.email]);

  useEffect(() => {
    const fetchTimerRecord = async () => {
      try {
        const response = await fetch(
          "https://hr-backend-72v1.onrender.com/get-timer-record",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user.email }), // Send email in the request body
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Set the time and updatedAt values
          setTime(data.record.time); // Assuming your timer record has a 'time' field
          // setLastInactive(data.record.updatedAt); 
        } else {
          setTime(0);
        }
      } catch (error) {
        setTime(0);
      }
    };

    fetchTimerRecord();
  }, [user.email]);

  // useEffect(() => {
  //   if (lastInactive) {
  //     const formattedTime = formatTime(time);
  //     setAlertMessage(`You were inactive since ${new Date(lastInactive).toLocaleString()}. Your timer is going to start from ${formattedTime} seconds.`);
  //     setShowAlert(true);
  //   }
  // }, [lastInactive]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      // Listener for messages from the service worker (optional for handling updates)
      const swListener = (event) => {
        if (
          event.data.type === "timerUpdate" &&
          event.data.email === user.email
        ) {
          setTime(event.data.time); // Update time based on the service worker's data
        }
      };

      // Add the listener for service worker messages
      navigator.serviceWorker.addEventListener("message", swListener);

      return () => {
        navigator.serviceWorker.removeEventListener("message", swListener);
      };
    }
  }, [user.email]);

  // const hours = Math.floor(time / 3600);
  // const minutes = Math.floor((time % 3600) / 60);
  // const seconds = time % 60;

  // useEffect(() => {
  //   if (isRunning && !isPaused) {
  //     console.log(`Timer is running: ${hours}h ${minutes}m ${seconds}s`);
  //   }
  // }, [time, isRunning, isPaused, hours, minutes, seconds]);

  // Send timer actions to the service worker
  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      if (isRunning && !isPaused) {
        // Start the timer
        navigator.serviceWorker.controller.postMessage({
          action: "startTimer",
          name: user.name,
          email: user.email,
          time,
          isRunning: true,
          isPaused: false,
        });
      } else if (isPaused) {
        navigator.serviceWorker.controller.postMessage({
          action: "pauseTimer",
          name: user.name,
          email: user.email,
          isRunning: isRunning,
          isPaused: true,
        });

        // Alert after 1 minute of being paused
        timeoutRef.current = setTimeout(() => {
          if (isPaused && !isStopped) {
            navigator.serviceWorker.controller.postMessage({
              action: "alert",
              email: user.email,
              message: "User has not resumed the timer after 1 minute",
            });
          }
        }, 60000);
      } else if (!isRunning && !isStopped) {
        // Resume the timer
        navigator.serviceWorker.controller.postMessage({
          action: "resumeTimer",
          name: user.name,
          email: user.email,
          isRunning: true,
          isPaused: false,
        });
      } else if (isStopped) {
        // Stop the timer
        navigator.serviceWorker.controller.postMessage({
          action: "stopTimer",
          name: user.name,
          email: user.email,
          isRunning: false,
          isPaused: true,
        });
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isRunning, isPaused, isStopped, time, user.email]);

  // const sendToMongoDB = async (name, email, time) => {
  //   console.log("Sending data to MongoDB:", { name, email, time, updatedAt: new Date() });

  //   try {
  //     const response = await fetch("https://hr-backend-72v1.onrender.com/timer-records", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         name,
  //         email,
  //         time,
  //         updatedAt: new Date(),
  //       }),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       console.log("Successfully sent timer record to MongoDB:", data);
  //     } else {
  //       console.error("Failed to send timer record:", data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error sending timer record to MongoDB:", error);
  //   }
  // };

  // Start the timer
  const handleStart = async () => {
    console.log("Clicked.");
    setIsDisabled(true);
    setisDisabled2(false);
    let checker = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    const checkerDate = new Date(new Date(checker).toISOString());
    const now = new Date();
    const offset = now.getTimezoneOffset(); // Get timezone offset in minutes
    const localTime = new Date(now.getTime() - offset * 60000);
    const timerData = {
      email: user.email,
      name: user.name,
      date: localTime.toISOString(),
      time: null,
      term: "checkin",
      checker: checkerDate,
    };
    // console.log(timerData);
    try {
      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/storeTimerData",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(timerData),
        }
      );

      if (response.status === 401) {
        const data = await response.json();
        setAlertMessage(data.message);
        setMessageLine(data.time);
        setMessageToggle(true);
        setisDisabled2(false);
        setShowAlert(true);
        return;
      }
      if (response.status === 200) {
        setAlertMessage("Check In Successful");
        setMessageLine(localTime.toISOString());
        setMessageToggle(true);
        setisDisabled2(false);
        setShowAlert(true);
      }

      if (!response.ok) {
        console.error("Failed to store timer data");
      }
    } catch (error) {
      console.error("Error storing timer data:", error);
    }
  };

  // Pause the timer
  const handlePause = () => {
    setIsPaused(true);
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: "pauseTimer",
        name: user.name,
        email: user.email,
        isRunning: isRunning,
        isPaused: true,
      });
    }
  };

  // Resume the timer
  const handleResume = () => {
    setIsPaused(false);
    setIsStopped(false);
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: "resumeTimer",
        name: user.name,
        email: user.email,
        isRunning: true,
        isPaused: false,
      });
    }
    clearTimeout(timeoutRef.current);
  };

  // Stop the timer
  const handleStop = async () => {
    setIsRunning(false);
    setIsPaused(false);
    setIsStopped(true);

    const formattedTime = formatTime(time);
    let checker = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    const checkerDate = new Date(new Date(checker).toISOString());
    const now = new Date();
    const offset = now.getTimezoneOffset(); // Get timezone offset in minutes
    const localTime = new Date(now.getTime() - offset * 60000);
    const timerData = {
      email: user.email,
      name: user.name,
      date: null,
      time: localTime.toISOString(),
      term: "checkout",
      checker: checker,
    };

    try {
      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/storeTimerData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(timerData),
        }
      );

      if (!response.ok) {
        console.error("Failed to store timer data");
      }

      if (response.status === 201) {
        setAlertMessage("Check Out Successful");
        setShowAlert(true);
      }

      // if (response.ok) {
      //   console.log('Timer data stored successfully');
      // } else {
      //   console.error('Failed to store timer data');
      // }
    } catch (error) {
      console.error("Error storing timer data:");
    }

    // if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    //   navigator.serviceWorker.controller.postMessage({
    //     action: 'stopTimer',
    //     name: user.name,
    //     email: user.email,
    //     isRunning: false,
    //     isPaused: false,
    //   });
    // }
    // const userMail = user.email;
    // deleteTimerRecord(userMail);
  };

  // async function deleteTimerRecord(email) {
  //   try {
  //     const response = await fetch(`https://hr-backend-72v1.onrender.com/timer-records?email=${email}`, {
  //       method: "DELETE",
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       console.log("Timer record deleted successfully:", data);
  //     } else {
  //       console.error("Failed to delete timer record:", data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting timer record:", error);
  //   }
  // }

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  const beforeUnloadHandler = (event) => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.ready) {
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        if (!isStopped) {
          handlePause();
        }
      }
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then((boolean) => {
          console.log(`Service worker unregistered: ${boolean}`);
        });
      });
    }
  };

  const formatTime = (time) => {
    if (typeof time !== "number" || isNaN(time)) {
      return "00:00:00";
    }
    return new Date(time * 1000).toISOString().substr(11, 8);
  };

  return (
    <section>
      <div className="employee">
        <Sidebar
          user={user}
          Employee={Employee}
          onShowProfile={profileToggleHandler}
          onShowTimer={dashbaordToggleHandler}
          onShowEvents={eventsToggleHandler}
          onShowRequestLeave={requestALeaveToggleHandler}
          onShowEmployeesCountdown={employeesCountdownHandler}
          myProfileToggle={myProfileToggle}
          timerShowToggle={timerShowToggle}
          eventsToggle={eventsToggle}
          requestLeaveToggle={requestLeaveToggle}
          employeesCountdownToggle={employeesCountdownToggle}
        />

        <Snackbar
          open={showAlert}
          autoHideDuration={15000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseAlert} severity="info">
            {alertMessage}
          </Alert>
        </Snackbar>
        {/* <div>
          <NotificationComponent
            alertMessage={alertMessage}
            showAlert={showAlert}
            handleCloseAlert={handleCloseAlert}
          />

        </div> */}

        <div className="content">
          <Navbar user={user} />

          {timerShowToggle && (
            <div className="timer">
              <p>
                C H E C K<span className="countdown-space"></span>I N / C H E C
                K<span className="countdown-space"></span>O U T
              </p>

              {/* <div className="countdown-timer">
                
                <div className="colons">
                  <div className="rectangles">
                  </div>
                  <div className="rectangles">
                  </div>
                </div>
                <div className="minutes">
                  <div className="timer-blocks">
                    <p>{Math.floor((time % 3600) / 60)}</p>
                  </div>
                  <p>MIN</p>
                </div>
                <div className="colons">
                  <div className="rectangles">
                  </div>
                  <div className="rectangles">
                  </div>
                </div>
                <div className="seconds">
                  <div className="timer-blocks">
                    <p>{time % 60}</p>
                  </div>
                  <p>SEC</p>
                </div> 
              </div> */}
              {!showMainReport && (
                <>
                  <div className="toggles">
                    <button
                      onClick={handleStart}
                      disabled={isDisabled}
                      className="start toggle-btns"
                    >
                      Check In
                      <span style={{ marginRight: "2px" }}></span>
                      <PlayArrowOutlinedIcon sx={{ fontSize: "xx-large" }} />
                    </button>
                    {/* <button onClick={handlePause} className="pause toggle-btns">
                    Pause
                    <img className="toggleImgs" src={PauseIcon} alt="Pause Icon" width="24" height="24" />
                  </button>
                  <button onClick={handleResume} className="resume toggle-btns">
                    Resume
                    <img className="toggleImgs" src={ResumeIcon} alt="Resume Icon" width="24" height="24" />
                  </button> */}
                      <button
                        className="stop toggle-btns"
                        disabled={isDisabled2}
                        onClick={handleStopClick}
                      >
                        Check Out
                        <span style={{ marginRight: "10px" }}></span>
                        <LogoutIcon />
                        {/* <img className="toggleImgs" src={StopIcon} alt="Stop Icon" width="24" height="24" /> */}
                      </button>
                  </div>
                  {messageToggle && (
                    <div className="hours">
                      <p>Checked In At</p>
                      <div className="timer-blocks">
                        <p>
                        ⏱&nbsp;{new Intl.DateTimeFormat("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                            timeZone: "UTC",
                          }).format(new Date(messageLine))}
                          <hr />
                          {new Date(messageLine).toISOString().split("T")[0]}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {showMainReport && (
                <div className="main-report">
                  <div className="submit-report-head">
                    <p>Submit Report</p>
                  </div>

                  {/* <div className="project-type">
                    <FormControl fullWidth>
                      <InputLabel id="progress-report-submission">
                        Mastermind / Progress Report
                      </InputLabel>
                      <Select
                        labelId="progress-report-submission"
                        id="progress"
                        value={selectedProject}
                        label="Mastermind / Progress Report"
                        onChange={handleProjectDropChange}
                      >
                        <MenuItem value="MasterMind">MasterMind</MenuItem>
                        <MenuItem value="Progress Report">
                          Progress Report
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* {selectedProject === "MasterMind" && (
                    <div className="mastermind-submit">
                      <div className="appointments">
                        <TextField
                          margin="dense"
                          id="appointmentTaken"
                          label="Appointment Taken"
                          type="number"
                          fullWidth
                          value={appointmentTaken}
                          onChange={handleAppointmentTakenChange}
                        />
                      </div>
                      <div className="emailsent">
                        <FormControl fullWidth>
                          <InputLabel id="email-sent">Email Sent</InputLabel>
                          <Select
                            labelId="email-sent"
                            id="email-sent"
                            value={emailSent}
                            label="Email Sent"
                            onChange={handleEmailSentChange}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="pipelines">
                        <FormControl fullWidth>
                          <InputLabel id="pipeline-moved">
                            Pipeline Moved
                          </InputLabel>
                          <Select
                            labelId="pipeline-moved"
                            id="pipeline-moved"
                            value={pipelineMoved}
                            label="Pipeline Moved"
                            onChange={handlePipelineMovedChange}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )} */}

                  <div className="progress-report-submit">
                    <TextField
                      margin="dense"
                      id="reportdetail"
                      label="Report Detail"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={reportDetail}
                      onChange={handleReportDetail}
                      sx={{ flex: 1 }}
                    />
                  </div>
                  <div className="report-submit">
                    <button className="submit-btn" onClick={handleReportSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* MY PROFILE COMPONENT SHOULD BE SHOWN HERE........ */}
          {myProfileToggle && (
            <>
              {/* <h1 className="heading">Profile Section Coming Soon !</h1> */}
              <PersonalDetails
                user={user}
                Employee={Employee}
                changeEmployeeInfo={handleEmployeeChange}
              />
            </>
          )}
          {eventsToggle && (
            // <h1 className="heading">Events Coming Soon !</h1>
            <Events user={user} />
          )}
          {requestLeaveToggle && (
            <RequestLeave user={user} Employee={Employee} />
          )}
          {employeesCountdownToggle && <Dashboard user={user} />}
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you ready to Submit Progress Report?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm if you're ready to submit the progress report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Employee;
