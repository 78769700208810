import React, { useEffect, useState } from "react";
import "./Projects.css";
import logo from "../../../utilities/logo-png.png";
import handshake from "../../../utilities/handshake.png";
import OtherProjects from "./OtherProjects";
import Retainers from "./Retainers";
import ProjectsImage from "../../../utilities/projects.svg";
// import MastermindImage from "../../../utilities/mastermind.png";
import { Button, Card } from "react-bootstrap";
import NewProject from "./NewProject";

const Projects = () => {
  const demoProjects = ["Retainers", "Other Projects"];
  const [projectsToggle, setProjectsToggle] = useState("default");
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  // const [masterMindData, setMasterMindData] = useState();
  const [retainersData, setRetainersData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [createdEmails, setcreatedEmails] = useState(false);


  const newProjectHandler = () => setProjectsToggle("new");
  const backHandler = () => {
    setProjectsToggle("default");
    setSelectedProjectName(null);
  };

  // useEffect(() => {
  //   async function fetchPredefinedData() {
  //     try {
  //       const response = await fetch("https://hr-backend-72v1.onrender.com/projectashow");
  //       const data = await response.json();
  //       setMasterMindData(data);
  //     } catch (error) {
  //       console.error("Error fetching project A data:");
  //     }

  //     // const retainers = [
  //     //   { name: "Fake Khaleeq", joiningDate: "2/4/2022", clientName: "Gary K.", status: "Inactive" },
  //     //   { name: "Fake Abdul rehman", joiningDate: "2/4/2022", clientName: "Paul A.", status: "Inactive" },
  //     //   { name: "Fake Person", joiningDate: "2/4/2022", clientName: "Methew J.", status: "Inactive" },
  //     //   { name: "Fake Person", joiningDate: "2/4/2022", clientName: "Mike R.", status: "Inactive" },
  //     // ];
  //     // setRetainersData(retainers);
  //   }

  //   fetchPredefinedData();
  // }, []);

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     try {
  //       const response = await fetch("https://hr-backend-72v1.onrender.com/getallprojects");
  //       if (response.ok) {
  //         const fetchedProjects = await response.json();
  //         setProjects(fetchedProjects);
  //       } else {
  //         throw new Error("Failed to fetch projects");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching projects:");
  //     }
  //   };

  //   fetchProjects();
  // }, []);

  useEffect(() => {
    const fetchRetainers = async () => {
      try {
        const response = await fetch(
          "https://hr-backend-72v1.onrender.com/api/retainers/with-employees"
        );
        const data = await response.json();
        // Flatten the data for easier use in the table
        const flattenedData = data.flatMap((retainer) =>
          retainer.employees.map((emp) => ({
            ...emp,
            clientName: retainer.clientName,
          }))
        );
        // console.log("flattened ",flattenedData);
        setRetainersData(flattenedData);
      } catch (error) {
        console.error("Error fetching retainers and employees:");
      }
    };

    fetchRetainers();
  }, []);

  return (
    <div className="proj_main">
      {projectsToggle === "default" && (
        <>
          <div className="proj_mainImage">
            <img src={ProjectsImage} width="40%" />
          </div>
          <div className="proj_middle">
            <h4 className="myTableHeader projHeader animate__animated animate__lightSpeedInLeft">
              On Going Projects
            </h4>
            {/* <Button onClick={newProjectHandler}>Create New Project</Button> */}
          </div>
          <hr style={{ width: "90%", margin: "5px auto" }} />

          <div className="proj_bottom">
            {demoProjects.map((projectName) => (
              <Card
                variant="outlined"
                className="proj_card animate__animated animate__fadeInRight"
                onClick={() => setProjectsToggle(projectName)}
                key={projectName}
              >
                <img
                  src={projectName === "Retainers" ? handshake : logo}
                  height="200px"
                />
                <h3>{projectName}</h3>
              </Card>
            ))}
          </div>
        </>
      )}

      {projectsToggle === "Other Projects" && (
        <OtherProjects
          heading={projectsToggle}
          image={projectsToggle.replace(/\s+/g, "")}
          onBack={backHandler}
          retainersData={retainersData}
        />
      )}

      {/* {projectsToggle === "Master Mind" && (
        <OtherProjects heading={projectsToggle} image={projectsToggle.replace(/\s+/g, "")} onBack={backHandler} masterMindData={masterMindData} />
      )} */}
      {projectsToggle === "Retainers" && (
        <Retainers
          heading={projectsToggle}
          image={projectsToggle.replace(/\s+/g, "")}
          onBack={backHandler}
          retainersData={retainersData}
        />
      )}
      {/* {projectsToggle === "new" && <NewProject onBack={backHandler} />} */}
    </div>
  );
};

export default Projects;
