import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import GreenLogo from "../../../utilities/hr-dashboard-green-icon.svg";
// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";
import "./Dashboard.css";

const Dashboard = (props) => {
  const { user } = props;
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [activeEmployeesCount, setActiveEmployeesCount] = useState(0);
  const [activeEmployees, setActiveEmployees] = useState([]);
  // const [managerEmployees, setManagerEmployees] = useState([]);

  const fetchTimers = async () => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/getAllTimers");
      if (!response.ok)
        throw new Error("Failed to fetch Check Ins in Dashboard");
      const data = await response.json();
      // console.log("DATA from Check Ins:", data);

      const twelveHoursAgo = new Date();
      twelveHoursAgo.setHours(twelveHoursAgo.getHours() - 12);

      let activeData = data.reverse()
        .filter(
          (a) => a.checkOut === null && new Date(a.checkIn) >= twelveHoursAgo
        )
        .filter(
          (obj, index, self) =>
            self.findIndex((o) => o.email === obj.email) === index
        );

      if (user.role === "Employee") {
        const response = await fetch(
          `https://hr-backend-72v1.onrender.com/employees-under-manager?email=${user?.email}`
        );
        const data = await response.json();
        activeData = activeData.filter((emp) =>
          data.some((item) => item.email === emp.email)
        );
      }
      // console.log("Active Employees Data:", activeData);
      setActiveEmployeesCount(activeData.length);
      setActiveEmployees(activeData);
    } catch (error) {
      console.error("Error fetching timer data:", error);
    }
  };

  const fetchUnderManagerEmployees = async () => {
    const email = user.email;
    try {
      const response = await fetch(
        `https://hr-backend-72v1.onrender.com/employees-under-manager?email=${email}`
      );
      const data = await response.json();

      const twelveHoursAgo = new Date();
      twelveHoursAgo.setHours(twelveHoursAgo.getHours() - 12);

      const activeDataM = data.filter(
        (a) => a.checkOut === null && new Date(a.checkIn) >= twelveHoursAgo
      );
      console.log(data);
      setActiveEmployeesCount(activeDataM.length);
      setActiveEmployees(activeDataM);
    } catch (error) {
      console.error("Error fetching employee data:");
    }
  };

  // const fetchAllTimers = async () => {
  //   try {
  //     const response = await fetch("https://hr-backend-72v1.onrender.com/timers");
  //     const data = await response.json();
  //     setActiveEmployeesCount(data.timers.length);
  //     setEmployees(data.timers);
  //   } catch (error) {
  //     console.error("Error fetching timers:", error);
  //   }
  // };

  // const fetchEmployeeTimers = async () => {
  //   try {
  //     const response = await fetch("https://hr-backend-72v1.onrender.com/stdtimers", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ managerEmail: user.email }),
  //     });
  //     const data = await response.json();
  //     setActiveEmployeesCount(data.timers.length);
  //     setEmployees(data.timers);
  //   } catch (error) {
  //     console.error("Error fetching employee timers:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUnderManagerEmployees();
  //   console.log("INN");
  // }, []);

  useEffect(() => {
    if (user.role === "HR" || user.role === "Employee") {
      // fetchAllTimers();
      fetchTimers();
      const intervalId = setInterval(fetchTimers, 6000);
      return () => clearInterval(intervalId);
    }
  }, [user.role]);

  const fetchTotalEmployees = async () => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/totalEmployees");
      const data = await response.json();
      setTotalEmployees(data.totalEmployees);
    } catch (error) {
      console.error("Error fetching total employees:", error);
    }
  };

  const fetchTotalManagerEmployees = async () => {
    try {
      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/managerEmployeesCount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ managerEmail: user.email }),
        }
      );
      const data = await response.json();
      // console.log(data , data.employees,  "total length : " , data.employees.length);
      setTotalEmployees(data.employees.length);
    } catch (error) {
      console.error("Error fetching total employees:", error);
    }
  };

  useEffect(() => {
    if (user.role === "HR") {
      fetchTotalEmployees();
    } else if (user.role === "Employee") {
      fetchTotalManagerEmployees();
    }
  }, [user.role]);

  const data = [
    { label: "Total Employees", count: totalEmployees },
    { label: "Active Employees", count: activeEmployeesCount },
  ];

  const formatTime = (timeInSeconds) => {
    const hrs = Math.floor(timeInSeconds / 3600);
    const mins = Math.floor((timeInSeconds % 3600) / 60);
    const secs = timeInSeconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <div className="dashboardFront">
        {data.map((item, index) => (
          <Card variant="outlined" className="card" key={index}>
            <Card.Header className="cardHeader">
              <span>{item.label}</span>
              <span>
                <img src={GreenLogo} alt="Green Logo" />
              </span>
            </Card.Header>
            <Card.Body className="cardBody">
              <span>{item.count}</span>
              <span>Employees</span>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="dashboardBottom">
        <TableContainer
          component={Paper}
          className="dashboardTimerTableContainer"
          variant="outlined"
        >
          <h4 className="myTableHeader">EMPLOYEE STATUS</h4>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="dashboardTableRow">
                <TableCell className="myTableHeadCells">
                  <span className="myTableHeadSpan">Name</span>
                </TableCell>
                <TableCell className="myTableHeadCells" align="left">
                  <span className="myTableHeadSpan">Email</span>
                </TableCell>
                <TableCell className="myTableHeadCells" align="left">
                  <span className="myTableHeadSpan">Online</span>
                </TableCell>
                {/* <TableCell className="myTableHeadCells" align="right"><span className="myTableHeadSpan">Status</span></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {activeEmployees.map((emp) => (
                <TableRow className="dashboardTableRow" key={emp.email}>
                  <TableCell sx={{ fontSize: "large" }}>{emp.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {emp.email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      timeZone: "UTC",
                    }).format(new Date(emp.checkIn))}
                  </TableCell>
                  {/* <TableCell sx={{ fontSize: "large" }} align="right">
                    <span className={emp.isRunning ? "active" : "paused"}>
                      {emp.isRunning && !emp.isPaused ? "Running" : emp.isRunning && emp.isPaused ? "Paused" : "Stopped"}
                    </span>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Dashboard;
