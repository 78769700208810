// AddEmployee.js
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./AddEmployee.css";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { create } from "@mui/material/styles/createTransitions";
import { DoorBack } from "@mui/icons-material";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import AdapterDayjs from '@mui/x-date-pickers/AdapterDayjs';
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const AddEmployee = ({ addEmployeeToggle }) => {
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [bankD, setBankD] = useState("");
  const [pos, setpos] = useState("");
  const [cnic, setCNIC] = useState("");

  const [joiningDate, setJoiningDate] = useState(dayjs());
  const [dob, setDOB] = useState(dayjs());

  const [designation, setDesignation] = useState("Employee");
  const [salary, setSalary] = useState("");
  const [pass, setPass] = useState("");
  const [required, setrequired] = useState(false);
  const [savedSuccessful, setsavedSuccessful] = useState(false);
  const [createdEmails, setcreatedEmails] = useState([]);
  const [alreadyCreatedEmp, setalreadyCreatedEmp] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [department, setDepartment] = useState("");
  const [manager, setManager] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [allManagers, setAllManagers] = useState("");

  const allDepartments = [
    "Content Team",
    "Dev Team",
    "Finance",
    "HR",
    "Media Buying",
    "Marketing Team",
  ];

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleBankD = (e) => {
    setBankD(e.target.value);
  };
  const handleJoining = (date) => {
    setJoiningDate(date);
  };
  const handleDOB = (date) => {
    setDOB(date);
    //     let dobMonth = parseInt(date.month());
    //     let dobDay = parseInt(date.date());
    //     let dobYear = parseInt(date.year());
    //     if (dobYear < 100) {
    //       dobYear += 2000;
    //     }
    //     let eDOB = new Date(dobYear, dobMonth, dobDay);
    // console.log("EDOB",eDOB);
  };
  const handleSalary = (e) => {
    setSalary(e.target.value);
  };
  const handlePass = (e) => {
    setPass(e.target.value);
  };
  const handleDepartment = (event) => {
    setDepartment(event.target.value);
  };
  const handlePos = (e) => {
    setpos(e.target.value);
  };
  const handleCNIC = (e) => {
    setCNIC(e.target.value);
  };
  const handleManager = (event) => {
    setManager(JSON.parse(event.target.value));
    // console.log("manag", manager);
  };

  const sendEmail = () => {
    const templateParams = {
      to_name: Name,
      to_email: email,
      pass: pass,
      from_name: "HR",
      from_email: "hr@growthguild.us",
    };

    emailjs
      .send(
        "service_3p7klz8",
        "template_97o71qy",
        templateParams,
        "e8b24L9zqgYrt6tz9"
      )
      .then((response) => {
        console.log("SUCCESS!  Sent Email");
      })
      .catch((err) => {
        console.error("FAILED...  While sending Email", err);
      });
  };

  const handleSend = async () => {
    setIsProcessing(true);
    setrequired(false);
    setalreadyCreatedEmp(false);
    setsavedSuccessful(false);
    // let date = "";
    let joinMonth = parseInt(joiningDate.month());
    let joinDay = parseInt(joiningDate.date());
    let joinYear = parseInt(joiningDate.year());
    if (joinYear < 100) {
      joinYear += 2000;
    }
    let eJoining = new Date(joinYear, joinMonth, joinDay);

    let dobMonth = parseInt(dob.month());
    let dobDay = parseInt(dob.date());
    let dobYear = parseInt(dob.year());
    if (dobYear < 100) {
      dobYear += 2000;
    }
    let eDOB = new Date(dobYear, dobMonth, dobDay);
    // console.log("EDOB", eDOB);
    if (Name !== "" && email !== "" && pass !== "") {
      let alreadyExists = false;

      createdEmails.forEach((element) => {
        if (element.email === email) {
          setalreadyCreatedEmp(true);
          alreadyExists = true;
          return;
        }
      });

      if (alreadyExists) {
        setIsProcessing(false);
        return;
      }
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/employee", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: Name,
            email: email,
            phone: phone,
            address: address,
            bankD: bankD,
            joining: eJoining,
            dob: eDOB,
            department: department,
            leadManager: manager.name,
            designation: designation,
            salary: salary,
            pass: pass,
            pos: pos,
            cnic: cnic,
          }),
        });
        const response2 = await fetch("https://hr-backend-72v1.onrender.com/assign-employee", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            managerEmail: manager.email,
            employeeName: Name,
            employeeEmail: email,
          }),
        });
        console.log("responses", response, response2);
        if (response.ok) {
          console.log("Employee data saved successfully !");
          sendEmail();
          setrequired(false);
          setsavedSuccessful(true);
          setShowAlert(true);
        } else {
          console.error("Error saving employee data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setrequired(true);
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/employees/emails");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setcreatedEmails(data);
        // console.log(data);
      } catch (error) {
        console.error("Error fetching emails:");
      }
    };

    fetchEmails();
  }, [savedSuccessful]);
  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await fetch(
          "https://hr-backend-72v1.onrender.com/employees-with-manager"
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setAllManagers(data);
      } catch (error) {
        console.error("Error fetching managers:");
      }
    };
    fetchManagers();
    // console.log(allManagers);
  }, []);

  return (
    <div style={{ marginLeft: "5%" }}>
      <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
        Add Employee Details
      </h4>
      <h4>Basic Information</h4>
      <div className="form">
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            required
            onChange={handleName}
            className="inputs-employee"
            label="Full Name"
            variant="standard"
          />
          <TextField
            onChange={handleAddress}
            className="inputs-employee"
            label="Address"
            variant="standard"
          />
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            onChange={handlePhone}
            className="inputs-employee"
            label="Phone"
            variant="standard"
          />
          <TextField
            onChange={handleCNIC}
            className="inputs-employee"
            label="CNIC*"
            variant="standard"
          />
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <div className="date-picker" style={{ width: "47%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Joining Date"
                  value={joiningDate}
                  onChange={handleJoining}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="date-picker" style={{ width: "47%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date of Birth"
                  value={dob}
                  onChange={handleDOB}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <FormControl variant="standard" className="inputs-employee">
            <InputLabel>Department</InputLabel>
            <Select value={department} onChange={handleDepartment}>
              {allDepartments.map((dept) => (
                <MenuItem key={dept} value={dept}>
                  {dept}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" className="inputs-employee">
            <InputLabel>Manager</InputLabel>
            <Select value={manager.name} onChange={handleManager}>
              {allManagers !== "" &&
                allManagers.map((m) => (
                  <MenuItem
                    key={m.email}
                    value={JSON.stringify({ name: m.name, email: m.email })}
                  >
                    {`${m.name} - (${m.email})`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            onChange={handlePos}
            className="inputs-employee"
            label="Position*"
            variant="standard"
          />
          <TextField
            onChange={handleSalary}
            className="inputs-employee"
            label="Salary"
            variant="standard"
          />
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            required
            onChange={handleEmail}
            className="inputs-employee"
            label="Email"
            variant="standard"
          />
          <TextField
            required
            onChange={handlePass}
            className="inputs-employee"
            label="Login Password"
            variant="standard"
          />
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            onChange={handleBankD}
            className="inputs-employee"
            label="Bank Details"
            variant="standard"
          />
        </div>
        {required && (
          <h4 style={{ color: "red", marginLeft: "20px", marginTop: "15px" }}>
            Please fill all the Required Fields
          </h4>
        )}
        {savedSuccessful && (
          <>
            <h4
              style={{
                color: "#00c04b",
                marginLeft: "20px",
                marginTop: "15px",
              }}
            >
              ✔️ Employee Data Saved Successfully
            </h4>
            <Snackbar
              open={showAlert}
              autoHideDuration={6000}
              onClose={() => setShowAlert(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={() => setShowAlert(false)} severity="success">
                ✔️ Employee Data Saved Successfully
              </Alert>
            </Snackbar>
          </>
        )}
        {alreadyCreatedEmp && (
          <h4 style={{ color: "blue", marginLeft: "20px", marginTop: "15px" }}>
            Employee with the Given Email already exists
          </h4>
        )}
        <Button
          className="add-emp-btn"
          onClick={handleSend}
          disabled={isProcessing}
        >
          Add Employee
        </Button>
      </div>
    </div>
  );
};

export default AddEmployee;
