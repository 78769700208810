import React, { useState, useEffect } from "react";
import "./OtherProjects.css";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
// import MasterMind from "../../../utilities/mastermind.png";
import logo from "../../../utilities/logo-png.png";
import handshake from "../../../utilities/handshake.png";
import NewRetainer from "./NewRetainer";
import NewProject from "./NewProject";

const OtherProjects = (props) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState("all");
  const [projects, setProjects] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // const [showNew, setShowNew] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Effect to filter data based on search term
  // useEffect(() => {
  //   if (searchTerm) {
  //     const results = props.masterMindData.filter((report) =>
  //       report.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredData(results);
  //   } else {
  //     setFilteredData(props.masterMindData);
  //   }
  // }, [searchTerm, props.masterMindData]);

  useEffect(() => {
    // Show the message if it's present, and auto-hide after 5 seconds
    if (message) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Auto-hide after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message]);

  // const handleOpenRetainerAdd = () => {
  //   setopenRetainerAdd(true);
  // };

  // const handleCloseRetainerAdd = () => {
  //   setopenRetainerAdd(false);
  // };
  // const handleOpenRetainerEmployeeAdd = () => {
  //   setopenRetainerEmployeeAdd(true);
  // };

  const backHandler = () => {
    setShow("all");
  };

  const handleSendEmployee = async () => {
    try {
      const response = await fetch(
        "https://hr-backend-72v1.onrender.com/api/project/addemployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectName: props.heading, // Assuming 'heading' is the project name
            employee: {
              name: selectedEmail.name,
              email: selectedEmail.email,
              startingDate: new Date(), // Sets today's date
            },
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        // console.log('Employee added:', result);
        setMessage("Employee added successfully");
        setMessageType("success");
      } else {
        setMessage("Error Adding Employee");
        setMessageType("error");
        throw new Error(result.message);
      }
    } catch (error) {
      // console.error('Failed to add employee:', error.message);
      setMessage("Error Adding Employee");
      setMessageType("error");
    }
  };

  const calculateTenure = (joiningDate) => {
    const now = new Date();
    const joinDate = new Date(joiningDate);
    const years = now.getFullYear() - joinDate.getFullYear();
    const months = now.getMonth() - joinDate.getMonth() + years * 12;
    const days =
      now.getDate() >= joinDate.getDate()
        ? now.getDate() - joinDate.getDate()
        : new Date(
            joinDate.getFullYear(),
            joinDate.getMonth() + 1,
            0
          ).getDate() -
          joinDate.getDate() +
          now.getDate();

    const fullMonths = Math.floor(months + days / 30);
    const fullDays = Math.floor(days % 30);

    return `${fullMonths} month${fullMonths !== 1 ? "s" : ""} ${fullDays} day${
      fullDays !== 1 ? "s" : ""
    }`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [emailsRes, projectsRes] = await Promise.all([
          fetch("https://hr-backend-72v1.onrender.com/employees/emails"),
          fetch("https://hr-backend-72v1.onrender.com/getallprojects"),
        ]);

        if (!emailsRes.ok || !projectsRes.ok) {
          throw new Error("Failed to fetch data");
        }

        const [emails, fprojects] = await Promise.all([
          emailsRes.json(),
          projectsRes.json(),
        ]);

        setEmails(emails);
        setProjects(fprojects);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("Projjj", projects);
  return (
    <div>
      {showMessage && (
        <div
          className="animate__animated animate__slideInDown"
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={messageType} style={{ marginBottom: "20px" }}>
            {message}
          </Alert>
        </div>
      )}
      {show === "all" && (
        <>
          <div className="masterTop">
            <Button
              className="backButton"
              sx={{ border: "2px solid", marginLeft: "5%" }}
              onClick={props.onBack}
            >
              <ArrowBackIosNewIcon fontSize="large" />
            </Button>
            <Button onClick={() => setShow("new")}>Create New Project</Button>
            {/* <div>
          {props.image === "OtherProjects" && (
            <TextField
              className="masterInput"
              label="Add Employee"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonAddAltIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4 className="myTableHeader masterHeader animate__animated animate__lightSpeedInLeft">
              {/* {props.heading} */}
              PROJECTS
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "65%",
              }}
            >
              {/* <Autocomplete
                    sx={{ width: "50%", margin: "0px" }}
                    options={emails}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.name || ""} - ${option.email || ""}`
                        : ""
                    }
                    value={selectedEmail}
                    onChange={(event, newValue) => {
                      setSelectedEmail(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Employee"
                        variant="outlined"
                      />
                    )}
                  /> */}
              {/* {selectedEmail && (
                    <Button onClick={handleSendEmployee}>Add Employee</Button>
                  )} */}
            </div>
          </div>

          <div className="masterMid">
            <Table sx={{ minWidth: 250 }} className="masterTable">
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Total Projects</span>
                  </TableCell>
                  <TableCell sx={{ fontSize: "larger" }} className="numbers">
                    {projects && projects.length}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>

            <div className="masterImage">
              <img
                src={props.image === "OtherProjects" ? logo : handshake}
                width="30%"
              />
            </div>
          </div>

          {/* <div className="masterSearch">
          <h2
            style={{
              fontSize: "x-large",
              color: "gray",
              margin: "10px 10px",
              fontWeight: "700",
            }}
          >
            Search
          </h2>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "80%", margin: "10px 10px" }}
          />
        </div> */}

          <TableContainer
            component={Paper}
            className="leaveContainer"
            style={{ width: "100% !important" }}
          >
            <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
              On Going Projects
            </h4>
            <Table sx={{ minWidth: 650 }} className="leaveTable">
              {props.image === "OtherProjects" && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Project</span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Type</span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">
                          Working Employee
                        </span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Starting</span>
                      </TableCell>
                      {/* <TableCell className="myTableHeadCells" align="right">
                    <span className="myTableHeadSpan">Status</span>
                  </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((p) => {
                      return (
                        <TableRow
                          key={p._id}
                          onClick={() => {setSelectedProject(p); setShow("one")}}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                        >
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.projectName}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.projectType}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.employees.length}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {new Date(p.startingDate).toLocaleDateString({
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
              {props.projectsToggle === "showProject" && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "left" }}
                      >
                        <span className="myTableHeadSpan">Name</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "left" }}
                      >
                        <span className="myTableHeadSpan">Email</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "right" }}
                      >
                        <span className="myTableHeadSpan">Start Date</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "right" }}
                      >
                        <span className="myTableHeadSpan">Status</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.project &&
                      props.project.employees &&
                      props.project.employees.length > 0 &&
                      props.project.employees.map((employee) => (
                        <TableRow key={employee._id}>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "left" }}
                          >
                            {employee.name}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "left" }}
                          >
                            {employee.email}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "right" }}
                          >
                            {employee.startingDate.split("T")[0]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "right" }}
                          >
                            <span className="active">{"active"}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </>
      )}
      {show === "new" && <NewProject onBack={backHandler} />}
      {show === "one" && (
        <>
          <div className="masterTop">
            <Button
              className="backButton"
              sx={{ border: "2px solid", marginLeft: "5%" }}
              onClick={backHandler}
            >
              <ArrowBackIosNewIcon fontSize="large" />
            </Button>
            {/* <Button onClick={() => setShow("new")}>Create New Project</Button> */}
            {/* <div>
          {props.image === "OtherProjects" && (
            <TextField
              className="masterInput"
              label="Add Employee"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonAddAltIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4 className="myTableHeader masterHeader animate__animated animate__lightSpeedInLeft">
              {/* {props.heading} */}
              {selectedProject?.projectName}
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "65%",
              }}
            >
              {/* <Autocomplete
                    sx={{ width: "50%", margin: "0px" }}
                    options={emails}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.name || ""} - ${option.email || ""}`
                        : ""
                    }
                    value={selectedEmail}
                    onChange={(event, newValue) => {
                      setSelectedEmail(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Employee"
                        variant="outlined"
                      />
                    )}
                  /> */}
              {/* {selectedEmail && (
                    <Button onClick={handleSendEmployee}>Add Employee</Button>
                  )} */}
            </div>
          </div>

          <div className="masterMid">
            <Table sx={{ minWidth: 250 }} className="masterTable">
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Total Projects</span>
                  </TableCell>
                  <TableCell sx={{ fontSize: "larger" }} className="numbers">
                    {projects && projects.length}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>

            <div className="masterImage">
              <img
                src={props.image === "OtherProjects" ? logo : handshake}
                width="30%"
              />
            </div>
          </div>

          {/* <div className="masterSearch">
          <h2
            style={{
              fontSize: "x-large",
              color: "gray",
              margin: "10px 10px",
              fontWeight: "700",
            }}
          >
            Search
          </h2>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "80%", margin: "10px 10px" }}
          />
        </div> */}

          <TableContainer
            component={Paper}
            className="leaveContainer"
            style={{ width: "100% !important" }}
          >
            <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
              On Going Projects
            </h4>
            <Table sx={{ minWidth: 650 }} className="leaveTable">
              {props.image === "OtherProjects" && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Project</span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Type</span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">
                          Working Employee
                        </span>
                      </TableCell>
                      <TableCell className="myTableHeadCells">
                        <span className="myTableHeadSpan">Starting</span>
                      </TableCell>
                      {/* <TableCell className="myTableHeadCells" align="right">
                    <span className="myTableHeadSpan">Status</span>
                  </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((p) => {
                      return (
                        <TableRow key={p._id}>
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.projectName}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.projectType}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {p.employees.length}
                          </TableCell>
                          <TableCell sx={{ fontSize: "large" }}>
                            {new Date(p.startingDate).toLocaleDateString({
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
              {props.projectsToggle === "showProject" && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "left" }}
                      >
                        <span className="myTableHeadSpan">Name</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "left" }}
                      >
                        <span className="myTableHeadSpan">Email</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "right" }}
                      >
                        <span className="myTableHeadSpan">Start Date</span>
                      </TableCell>
                      <TableCell
                        className="myTableHeadCells"
                        style={{ textAlign: "right" }}
                      >
                        <span className="myTableHeadSpan">Status</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.project &&
                      props.project.employees &&
                      props.project.employees.length > 0 &&
                      props.project.employees.map((employee) => (
                        <TableRow key={employee._id}>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "left" }}
                          >
                            {employee.name}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "left" }}
                          >
                            {employee.email}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "right" }}
                          >
                            {employee.startingDate.split("T")[0]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "large", textAlign: "right" }}
                          >
                            <span className="active">{"active"}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default OtherProjects;
